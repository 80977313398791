<template>
	<div class="page settings">
    <nav class="collapse d-lg-block sidebar collapse show">
      <div class="position-sticky">
        <div class="list-group list-group-flush mx-3 mt-4">
          <router-link v-if="currentAccount.hasPermission('admin.companies.view')" to="/settings/companies" class="list-group-item list-group-item-action py-2 ripple">
            <i class="fa fa-building me-3"></i>
            <span>Companies</span>
          </router-link>

          <router-link to="/settings/accounts" class="list-group-item list-group-item-action py-2 ripple">
            <i class="fa fa-users me-3"></i>
            <span>Accounts</span>
          </router-link>

          <router-link to="/settings/vehicle-types" class="list-group-item list-group-item-action py-2 ripple">
            <i class="fa fa-car me-3"></i>
            <span>Vehicle types</span>
          </router-link>

          <router-link to="/settings/equipments" class="list-group-item list-group-item-action py-2 ripple">
            <i class="fa fa-tools me-3"></i>
            <span>Equipments</span>
          </router-link>
        </div>
      </div>
    </nav>

    <router-view></router-view>
	</div>
</template>

<script>
  import { mapGetters } from "vuex";

	export default {
		name: "SettingsPage",
    computed: {
			...mapGetters([
				'account/current',
			]),
			currentAccount() {
				return this['account/current'];
			},
		}
	}
</script>

<style scoped>
  .settings {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  [data-theme="dark"] .list-group-item {
    background-color: #2a2a2e;
    color: #fff;
  }

  [data-theme="dark"] .list-group-item:hover {
    background-color: #0d6efd;
  }
</style>
